import logo from './logo.svg';
import './App.css';
import React, { Component }  from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Portfolio
        </p>
      </header>
    </div>
  );
}

export default App;
